@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@700&display=swap");
.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
}
.card-flip {
    margin: 0 1rem;
}
.card-flip:first-child {
    margin-left: 0;
}
.card-flip:last-child {
    margin-right: 0;
}
.card-number {
    display: block;
    position: relative;
    min-width: 11rem;
    padding: 1.5rem .8rem;
    margin-bottom: 1.5rem;
    font-size: 16rem;
    background-color: hsl(236, 21%, 26%);
    color: hsl(324deg 99% 54%);
    letter-spacing: normal;
    border-radius: .5rem;
    box-shadow: 0 .65rem hsl(234, 17%, 12%);
    overflow: hidden;
}

.card-p {

    color: #fe16a1;
    font-size: 2.5rem;

}
.card-number-top {
    position: absolute;
    width: 100%;
    height: 50%;
    inset: 0;
    background-color: rgba(0,0,0,.2);
    transition: .8s;
}
.card-number-top::before, .card-number-top::after {
    content: '';
    position: absolute;
    right: -.5rem;
    bottom: -.5rem;
    background-color: hsl(324deg 99% 54%);
    width: 1rem;
    height: 1rem;
    border-radius: 1rem;
}
.card-number-top::after {
    left: -.5rem;
}






.fold {
    -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
    transform-style: preserve-3d;
}

.unfold {
     animation: unfold 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.25s normal forwards;
    transform-style: preserve-3d;
}

@-webkit-keyframes fold {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-180deg);
    }
}

@keyframes fold {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(-180deg);
    }
}
@-webkit-keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}
@keyframes unfold {
    0% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(0deg);
    }
}

@media screen and (max-width: 1000px) {
    .card-number {
        min-width: auto;
        padding: 1rem .5rem;
        font-size: 3rem;
    }
    .card-number-top::before, .card-number-top::after {
        bottom: -.25rem;
        right: -.25rem;
        width: .5rem;
        height: .5rem;
    }
    .card-number-top::after {
        left: -.25rem;
    }
}
@media screen and (max-width: 480px) {
    .card-flip {
        margin: 0 1rem 0 0;
    }
    .card-flip:last-child {
        margin-right: 0;
    }
    .card-flip p {
        font-size: .6rem
    }
    .card-number {
        min-width: auto;
        padding: 1rem .5rem;
        font-size: 2rem;
        box-shadow: 0 .4rem hsl(234, 17%, 12%);
    }
}